import React from 'react';

const RightArrow = () => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="bath"
    role="img"
    viewBox="0 0 50 50"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="currentColor"
      d="M29.0962 23.1777L24.0398 18.1213L26.1612 16L35 24.8388L26.1612 33.6777L24.0398 31.5563L29.4185 26.1777L14 26.1777L14 23.1777L29.0962 23.1777Z"
      className=""
    />
  </svg>
);

export default RightArrow;
