import React, { FC, useMemo } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { useCurrentLang } from 'hooks/useCurrentLang';

import HomeIcon from 'common/IconsSVG/HomeIcon';
import Image from 'common/Image';

import { ILogo } from './models';

const Logo: FC<ILogo> = ({ customClass, swap = false, menuOpen, setIsMenuOpen }) => {
  const {
    currentLang: [currentLangCode],
  } = useCurrentLang();

  const {
    allBrandSettings: { nodes: brandSettingsNodes },
    allHomepage: { nodes: homepageNodes },
  } = useStaticQuery(graphql`
    {
      allBrandSettings {
        nodes {
          brandName
          brandLogo {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED)
            }
          }
          lang
        }
      }
      allHomepage {
        nodes {
          url
          lang
        }
      }
    }
  `);

  const { brandLogo, brandName } = brandSettingsNodes.find(({ lang }) => lang === currentLangCode);
  const { url } = homepageNodes.find(({ lang }) => lang === currentLangCode);

  const logoRender = useMemo(
    () => (
      <Link to={url} onClick={() => setIsMenuOpen && setIsMenuOpen(false)}>
        {swap && menuOpen ? (
          <HomeIcon />
        ) : (
          <Image className={customClass} imageData={brandLogo} alt={brandName} />
        )}
      </Link>
    ),
    [brandLogo, url, brandName, customClass, menuOpen, setIsMenuOpen, swap]
  );

  return logoRender;
};

export default Logo;
