import { useEffect, useState } from 'react';
import { breakpoints as breakpointsValues } from 'shared/static';

import { getWindowWidth } from 'utils/browser';

import { UseScreenServiceReturn } from './model';

export const useScreenService = (): UseScreenServiceReturn => {
  const [isSm, setIsSm] = useState<boolean>(false);
  const [isMd, setIsMd] = useState<boolean>(false);
  const [isLg, setIsLg] = useState<boolean>(false);
  const [isXl, setIsXl] = useState<boolean>(false);
  const [isXxl, setIsXxl] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const handleResize = () => {
    const width = getWindowWidth();
    setIsSm(width < breakpointsValues.sm);
    setIsMd(width >= breakpointsValues.sm && width < breakpointsValues.md);
    setIsLg(width >= breakpointsValues.md && width < breakpointsValues.lg);
    setIsXl(width >= breakpointsValues.lg && width < breakpointsValues.xl);
    setIsXxl(width >= breakpointsValues.xl);

    setIsMobile(width < breakpointsValues.md);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    window && window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    isSm,
    isMd,
    isLg,
    isXl,
    isXxl,
    isMobile,
  };
};
