import React, { FC, useState } from 'react';

import { Container } from 'layout';
import Logo from 'common/Logo';
import DesktopNavigation from 'components/DesktopNavigation';
import Hamburger from 'components/Hamburger';
import MobileNavigation from 'components/MobileNavigation';

import './Header.scss';

const Header: FC<CommonTypes.HeaderProps> = ({
  navigation,
  shopNowButton,
  menuLabel,
  closeLabel,
  countryDropdown,
  activeCountry,
}) => {
  const [isHamburgerOpen, setIsHamburgerOpen] = useState<boolean>(false);

  return (
    <Container fluid noPadding element="header" className="header">
      <Container className="header__wrapper">
        <Logo
          customClass="header__logo"
          swap
          menuOpen={isHamburgerOpen}
          setIsMenuOpen={setIsHamburgerOpen}
        />
        <DesktopNavigation
          navigation={navigation}
          shopNowButton={shopNowButton}
          countryDropdown={countryDropdown}
          activeCountry={activeCountry}
        />
        <MobileNavigation
          isOpen={isHamburgerOpen}
          setIsOpen={setIsHamburgerOpen}
          navigation={navigation}
          shopNowButton={shopNowButton}
          countryDropdown={countryDropdown}
          activeCountry={activeCountry}
        />
        <Hamburger
          isOpen={isHamburgerOpen}
          setIsOpen={() => setIsHamburgerOpen(!isHamburgerOpen)}
          ariaLabel="hamburger"
          customClass="hide--on-desktop"
          menuLabel={menuLabel}
          closeLabel={closeLabel}
        />
      </Container>
    </Container>
  );
};
export default Header;
