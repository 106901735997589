import React, { FC } from 'react';
import { config } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Link from 'common/Link';

import { IconProps } from './models';

import '@fortawesome/fontawesome-svg-core/styles.css';

const Icon: FC<IconProps> = ({ icon, url, className }) => {
  if (!icon) return null;

  config.autoAddCss = false;

  const iconComponent = <FontAwesomeIcon icon={icon} className={className} />;

  // eslint-disable-next-line jsx-a11y/anchor-is-valid
  const renderIcon = url ? <Link url={url}>{iconComponent}</Link> : iconComponent;

  return renderIcon;
};

export default Icon;
