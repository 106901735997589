import { ButtonBaseProps } from 'common/ButtonBase/models.d';

export namespace BUTTON {
  export enum COLOR {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    TERTIARY = 'tertiary',
  }

  export enum VARIANT {
    LINK = 'link',
    CONTAINED = 'contained',
    OUTLINE = 'outline',
    ACCORDION = 'accordion',
    ARROW = 'arrow',
    ARROW_CONTAINED = 'arrow-contained',
    PAGINATION = 'pagination',
    DROPDOWN = 'dropdown',
  }
}

export interface ButtonProps extends ButtonBaseProps {
  variant?: BUTTON.VARIANT;
  color?: BUTTON.COLOR;
  icon?: boolean;
  link?: string;
  customClass?: string;
}
