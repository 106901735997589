import React from 'react';

const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    className="media-icon"
  >
    <path
      d="M35 25.0609C35 19.5038 30.5234 15 25 15C19.4766 15 15 19.5038 15 25.0609C15 30.0835 18.6562 34.2454 23.4375 35V27.9691H20.8984V25.0609H23.4375V22.8444C23.4375 20.3232 24.9297 18.93 27.2148 18.93C28.3086 18.93 29.4531 19.1265 29.4531 19.1265V21.6025H28.1914C26.9492 21.6025 26.5625 22.3787 26.5625 23.1745V25.0609H29.3359L28.8926 27.9691H26.5625V35C31.3438 34.2454 35 30.0835 35 25.0609Z"
      fill="currentColor"
    />
  </svg>
);

export default FacebookIcon;
