import React, { FC } from 'react';

import Link from 'common/Link';
import Button, { BUTTON } from 'components/Button';
import CountryDropdown from 'components/CountryDropdown';

import './DesktopNavigation.scss';

const DesktopNavigation: FC<CommonTypes.HeaderProps> = ({
  navigation,
  shopNowButton: { buttonLink, buttonText, ariaLabel },
  countryDropdown,
  activeCountry,
}) => {
  const renderNavigation = (navigationArray: CommonTypes.NavigationType[]) => (
    <div className="desktop-navigation__menu">
      {navigationArray.map(({ label, url }) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          ariaLabel={label}
          url={url.url}
          key={label}
          customClass="desktop-navigation__item"
          activeClass="desktop-navigation__item--active"
        >
          {label}
        </Link>
      ))}
    </div>
  );

  return (
    <div className="desktop-navigation hide--on-mobile">
      {renderNavigation(navigation)}
      <section className="desktop-navigation__buttons">
        <CountryDropdown countryDropdown={countryDropdown} activeCountry={activeCountry} />
        <Button
          variant={BUTTON.VARIANT.CONTAINED}
          color={BUTTON.COLOR.SECONDARY}
          customClass="desktop-navigation__button-buy"
          ariaLabel={ariaLabel}
          link={buttonLink.url}
        >
          {buttonText}
        </Button>
      </section>
    </div>
  );
};

export default DesktopNavigation;
