import React, { FC } from 'react';

import { Container } from 'layout';
import Facebook from 'common/IconsSVG/FacebookIcon';
import { MEDIALINKS } from 'common/IconsSVG/mediaLinks.d';
import Youtube from 'common/IconsSVG/YoutubeIcon';
import Link from 'common/Link';
import Typography from 'common/Typography';

import './Footer.scss';

const Footer: FC<CommonTypes.FooterProps> = ({ navigation, mediaLinks, copyrightBlock }) => {
  const mediaLinksMap = {
    [MEDIALINKS.FACEBOOK]: <Facebook />,
    [MEDIALINKS.YOUTUBE]: <Youtube />,
  };

  const renderMediaLinks = mediaLinks.map(
    ({ iconVariant, link: url }: CommonTypes.MediaLinksType) => (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link key={iconVariant[0]} url={url.url}>
        {mediaLinksMap[iconVariant[0]]}
      </Link>
    )
  );

  const renderFooterNavigation = navigation.map(({ label, url }: CommonTypes.NavigationType) =>
    url ? (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link key={label} url={url.url}>
        {label}
      </Link>
    ) : null
  );

  return (
    <Container element="footer" fluid noPadding className="footer" data-testid="main-footer">
      <Container className="footer__wrapper">
        <div className="footer__media-links">{renderMediaLinks}</div>
        <div className="footer__navigation">{renderFooterNavigation}</div>
        <Typography
          dangerouslySetInnerHTML={copyrightBlock}
          customClass="footer__copyrights"
          variant="paragraph2"
        />
      </Container>
    </Container>
  );
};

export default Footer;
