import React from 'react';

const Youtube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    className="media-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.3584 29.2722V21.6661C36.3584 21.6661 36.3584 18 32.597 18H18.2982C18.2982 18 14.5394 18 14.5394 21.6661V29.2722C14.5394 29.2722 14.5394 32.9383 18.2982 32.9383H32.597C32.597 32.9383 36.3584 32.9383 36.3584 29.2722V29.2722ZM29.6849 25.4798L22.5406 29.5699V21.3884L29.6849 25.4798Z"
      fill="currentColor"
    />
  </svg>
);

export default Youtube;
