const h1 = 'h1';
const h2 = 'h2';
const h3 = 'h3';
const h4 = 'h4';
const h5 = 'h5';
const h6 = 'h6';
const p = 'p';
const span = 'span';

export const variantToComponentMapping = {
  heading1: h1,
  heading2: h2,
  heading3: h3,
  heading4: h4,
  heading5: h5,
  heading6: h6,
  heading7: h1,
  heading8: h5,
  paragraph1: p,
  paragraph2: p,
  paragraph3: p,
  paragraph4: p,
  paragraph5: p,
  hamburger: span,
};
