/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import classNames from 'classnames';

import Link from 'common/Link';
import Button, { BUTTON } from 'components/Button';
import CountryDropdown from 'components/CountryDropdown';

import { MobileNavigationProps } from './models';

import './MobileNavigation.scss';

const MobileNavigation: FC<MobileNavigationProps> = ({
  isOpen,
  setIsOpen,
  navigation,
  shopNowButton,
  countryDropdown,
  activeCountry,
}) => {
  const mobileMenuClassnames = classNames('mobile-navigation', {
    'mobile-navigation--open': isOpen,
  });

  const { buttonLink, buttonText, ariaLabel } = shopNowButton;

  const renderNavigation = navigation.map(({ label, url }) => (
    <Link
      url={url.url}
      customClass="mobile-navigation__item"
      key={label}
      activeClass="mobile-navigation__item--active"
      onClick={() => setIsOpen(false)}
    >
      {label}
    </Link>
  ));

  return (
    <div className={`${mobileMenuClassnames} hide--on-desktop`}>
      {renderNavigation}
      <Button
        ariaLabel={ariaLabel}
        link={buttonLink.url}
        color={BUTTON.COLOR.SECONDARY}
        variant={BUTTON.VARIANT.CONTAINED}
        customClass="button--buy-now-mobile"
        onClick={() => setIsOpen(false)}
      >
        {buttonText}
      </Button>
      <CountryDropdown countryDropdown={countryDropdown} activeCountry={activeCountry} />
    </div>
  );
};

export default MobileNavigation;
