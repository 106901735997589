/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState } from 'react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useScreenService } from 'hooks/useScreenService';

import Icon from 'common/Icon';
import Link from 'common/Link';
import Typography from 'common/Typography';
import Button, { BUTTON } from 'components/Button';

import { CountryDropdownProps } from './models';

import './CountryDropdown.scss';

const CountryDropdown: FC<CountryDropdownProps> = ({
  countryDropdown,
  activeCountry: { buttonText: activeCountry, ariaLabel },
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { isMobile } = useScreenService();

  const countryDropdownClassnames = classNames('country-dropdown', {
    'country-dropdown--active': isOpen || isMobile,
  });

  const renderButton = (
    <Button
      variant={BUTTON.VARIANT.DROPDOWN}
      color={BUTTON.COLOR.SECONDARY}
      customClass="country-dropdown__button hide--on-mobile"
      ariaLabel={ariaLabel}
      onClick={() => setIsOpen((prevState) => !prevState)}
    >
      {activeCountry.toUpperCase()}
      <Icon icon={faChevronDown} className="country-dropdown__icon" />
    </Button>
  );

  const renderCountryDropdown = countryDropdown?.map(({ country, langLink }) => (
    <ul key={country} className="country-dropdown__list">
      <Typography variant="label" weight="regular">
        {country}
      </Typography>
      {langLink?.map(({ url, name }) => {
        const langLinkClassnames = classNames('lang__link', {
          [`lang__link--active`]: name.toLowerCase() === activeCountry.toLowerCase(),
        });

        return (
          <li key={name} className="country-dropdown__lang">
            <Typography variant="label" weight="bold">
              <Link url={url} customClass={langLinkClassnames}>
                {name}
              </Link>
            </Typography>
          </li>
        );
      })}
    </ul>
  ));

  return (
    <section className={countryDropdownClassnames} data-testid="country-dropdown">
      {renderButton}
      <div className="country-dropdown__wrapper" data-testid="country-dropdown-list">
        {renderCountryDropdown}
      </div>
    </section>
  );
};

export default CountryDropdown;
