export const STRING = {
  BLANK_FIELD: '',
  SINGLE_SPACE: ' ',
  NEXT: 'next page',
  PREVIOUS: 'previous page',
  CATEGORY_TAG: 'category',
  PRODUCTS_PAGE: 'products',
  EDUCATION_PAGE: 'education',
  TITLE: 'Example title',
  CONTENT:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ex lorem, lobortis a dolor tristique, imperdiet eleifend mauris. Nullam aliquet, tellus a eleifend luctus, diam lectus sodales ante, et venenatis eros elit eu sem. Praesent in sem erat. Donec ex erat, venenatis a ultricies sed, auctor et lacus. Vestibulum imperdiet sapien et massa iaculis gravida. Integer et massa sapien. Vivamus at dui urna. Morbi consequat laoreet urna, vel lobortis leo ultricies id. Integer non dolor massa. Aenean molestie, felis porta bibendum tincidunt, lectus nisi porta orci, at lacinia nunc nisi ac odio.',
  HTML_CONTENT:
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque ex lorem, lobortis a dolor tristique, imperdiet eleifend mauris. Nullam aliquet, tellus a eleifend luctus, diam lectus sodales ante, et venenatis eros elit eu sem. Praesent in sem erat. Donec ex erat, venenatis a ultricies sed, auctor et lacus. Vestibulum imperdiet sapien et massa iaculis gravida. Integer et massa sapien. Vivamus at dui urna. Morbi consequat laoreet urna, vel lobortis leo ultricies id. Integer non dolor massa. Aenean molestie, felis porta bibendum tincidunt, lectus nisi porta orci, at lacinia nunc nisi ac odio.</p>',
  SHARE: 'share',
  PAGE: 'page',
  BUY_NOW: 'BUY NOW',
  TRAGET_BLANK: '_blank',
  WHATSAPP: 'WhatsApp',
};
