import React, { forwardRef } from 'react';
import classNames from 'classnames';

import ButtonBase from 'common/ButtonBase';
import RightArrow from 'common/IconsSVG/RightArrow';
import Link from 'common/Link';

import { BUTTON, ButtonProps } from './models.d';

import './Button.scss';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant = BUTTON.VARIANT.LINK,
      color = BUTTON.COLOR.PRIMARY,
      icon,
      link,
      children = '',
      customClass,
      ...baseProps
    },
    ref
  ) => {
    const buttonClasses = classNames(customClass, {
      [`button--${variant}`]: variant,
      [`button--${variant}--${color}`]: color,
      [`button--icon`]: icon,
    });

    const showIcon = icon ? <RightArrow /> : null;

    const buttonContent = (
      <span className="button__content">
        {showIcon}
        <span className="button__label">{children}</span>
      </span>
    );

    const buttonReturn = link ? (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        customClass={`button ${buttonClasses}`}
        url={link}
        onClick={() => baseProps.onClick && baseProps.onClick()}
      >
        {buttonContent}
      </Link>
    ) : (
      <ButtonBase className={buttonClasses} {...baseProps} ref={ref}>
        {buttonContent}
      </ButtonBase>
    );

    return buttonReturn;
  }
);

export default Button;
