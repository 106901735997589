import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import loadable from '@loadable/component';
import classNames from 'classnames';
import Footer from 'containers/Footer';
import Header from 'containers/Header';

import 'styles/main.scss';

import Loadable from 'components/Loadable';

import { LayoutProps } from './models';

const PreviewWidget = loadable(() => import('components/PreviewWidget'));

const Layout: FC<LayoutProps> = ({ children, headerTransparent, className }) => {
  const CSSclassNames = classNames('layout', className, {
    'layout--transparent': headerTransparent,
  });

  const { navigation, footer } = useStaticQuery(graphql`
    {
      navigation {
        navigation {
          url {
            name
            url
          }
          label
        }
        shopNowButton {
          buttonText
          buttonLink {
            name
            url
          }
          ariaLabel
        }
        menuLabel
        closeLabel
        countryDropdown {
          country
          langLink {
            name
            url
          }
        }
        activeCountry {
          ...ButtonFragment
        }
      }
      footer {
        mediaLinks {
          iconVariant
          link {
            name
            url
          }
        }
        navigation {
          label
          url {
            name
            url
          }
        }
        copyrightBlock
      }
    }
  `);

  const shouldLoadPreviewWidget = Boolean(Number(process.env.GATSBY_ENABLE_PREVIEW_WIDGET));

  const {
    navigation: headerNavigation,
    shopNowButton,
    menuLabel,
    closeLabel,
    countryDropdown,
    activeCountry,
  } = navigation;
  const { navigation: footerNavigation, mediaLinks, copyrightBlock } = footer;

  return (
    <div className={CSSclassNames}>
      <Loadable isActive={shouldLoadPreviewWidget}>
        <PreviewWidget cmsUrl={`${process.env.GATSBY_UMBRACO_BASE_URL}/umbraco`} />
      </Loadable>
      <Header
        navigation={headerNavigation}
        shopNowButton={shopNowButton}
        menuLabel={menuLabel}
        closeLabel={closeLabel}
        countryDropdown={countryDropdown}
        activeCountry={activeCountry}
      />

      <main id="main" className="layout__main">
        {children}
      </main>

      <Footer
        navigation={footerNavigation}
        mediaLinks={mediaLinks}
        copyrightBlock={copyrightBlock}
      />
    </div>
  );
};

export default Layout;
