import React, { FC } from 'react';
import classNames from 'classnames';

import Typography from 'common/Typography';
import Button from 'components/Button';
import { toggleState } from 'utils/toggleState';

import { HamburgerProps } from './models';

import './Hamburger.scss';

const Hamburger: FC<HamburgerProps> = ({
  isOpen,
  setIsOpen,
  customClass,
  ariaLabel,
  menuLabel,
  closeLabel,
}) => {
  const hamburgerClasses = classNames('hamburger', customClass, { 'hamburger--active': isOpen });

  return (
    <>
      <Typography variant="paragraph2" customClass="mobile-menu-label hide--on-desktop">
        {isOpen ? closeLabel : menuLabel}
      </Typography>
      <Button
        onClick={() => toggleState(setIsOpen)}
        aria-label={ariaLabel}
        type="button"
        className={hamburgerClasses}
      >
        <span className="hamburger__bar" />
        <span className="hamburger__bar" />
        <span className="hamburger__bar" />
      </Button>
    </>
  );
};

export default Hamburger;
