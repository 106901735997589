import React, { FC } from 'react';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';

import { TypographyProps } from './models';
import { variantToComponentMapping } from './utils';

import './Typography.scss';

const Typography: FC<TypographyProps> = ({
  variant,
  weight,
  align,
  component,
  dangerouslySetInnerHTML,
  children,
  customClass,
  innerRef,
}) => {
  const Component = component || variantToComponentMapping[variant] || 'span';

  const typographyClasses = classNames('typography', customClass, {
    [`typography--${variant}`]: variant,
    [`typography--${align}`]: align,
    [`typography--${weight}`]: weight,
  });

  const renderTypography = dangerouslySetInnerHTML ? (
    <DangerouslySetInnerHtml
      data-testid="typography"
      className={typographyClasses}
      html={dangerouslySetInnerHTML}
    />
  ) : (
    <Component data-testid="typography" className={typographyClasses} ref={innerRef}>
      {children}
    </Component>
  );

  return renderTypography;
};

export default Typography;
