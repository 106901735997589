import React from 'react';

const HomeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="51"
    viewBox="0 0 50 51"
    fill="none"
    className="home-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.8564 13L38.7128 25.75H34.8564V38H27.8564V30H21.8564V38H14.8564V25.75H11L24.8564 13Z"
      fill="currentColor"
    />
  </svg>
);

export default HomeIcon;
